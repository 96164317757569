<template>
  <section>
       <section class="navBack">
      <v-container class="container-custom">
        <v-row>
          <v-col md="12" class="topNav">
            <ul class="navList">
              <li>Home</li>
              <li><v-icon> mdi-chevron-right </v-icon> Charging Station</li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <v-container class="container-custom page py-10">
      <v-row v-if="loading">
        <v-col md="12">
          <Loading />
        </v-col>
      </v-row>
      <v-row v-if="!loading" class="filterSection">
        <v-col md="4">
          <div>
            <v-label>Vehicle Type</v-label>
            <v-autocomplete
              outlined
              placeholder="Select Vehicle Type"
              v-model="category"
              :items="categories"
              item-text="name"
              item-value="value"
              @change="selectCategory"
              multiple
            ></v-autocomplete>
          </div>
        </v-col>
        <v-col md="4">
          <div>
            <v-label>Brand</v-label>
            <v-autocomplete
              outlined
              placeholder="Select Brand"
              v-model="brand"
              :items="brands"
              item-text="value"
              item-value="value"
            ></v-autocomplete>
          </div>
        </v-col>
        <v-col md="4" class="d-flex align-center">
          <div>
            <v-btn @click="filter" v-if="!filterLoading">Filter</v-btn>
            <v-btn v-if="filterLoading" loading></v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="!loading">
        <v-col
          md="3"
          cols="6"
          v-for="(data, index) in chargingStations"
          :key="index + 'charging'"
        >
          <div class="divBox">
            <h1>
              {{ data.name }}
            </h1>
            <ul>
              <li>Address : {{ data.address }}</li>
              <li>City : {{ data.city }}</li>
              <li>Province : {{ data.province }}</li>
              <li>Phone : {{ data.telephone }}</li>
              <li>Brand : {{ data.brand != '' ? data.brand:'-' }}</li>
              <li>
                <span v-for="(dat, i) in data.type" :key="i + 'tp'" class="tag">
                  {{ dat.replace('4 Wheeler','Car') }}
                </span>
              </li>
              <li class="mt-3 d-flex align-center marker">
                <a
                  target="_blank"
                  :href="`http://maps.google.co.uk/maps?q=${data.latitude},${data.longitude}`"
                >
                  <v-icon small class="pr-1"> mdi-map-marker </v-icon> Map</a
                >
              </li>
            </ul>
            <p></p>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="total == 0">
        <v-col>
          <p>No Charging Station Found!</p>
        </v-col>
      </v-row>
      <v-row v-if="next">
        <v-col md="12" class="text-center viewMore mt-5" v-if="!loadMore">
          <v-btn large @click="nextAct"
            >Load More <v-icon> mdi-chevron-right </v-icon
            ><v-icon> mdi-chevron-right </v-icon></v-btn
          >
        </v-col>
        <v-col md="12" class="text-center viewMore mt-5" v-if="loadMore">
          <v-btn large loading></v-btn>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import Loading from "@/components/Loading";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ChargingStation",
  data: () => ({
    loading: true,
    limit: 10,
    page: 1,
    chargingStations: [],
    filters: [],
    brands: undefined,
    categories: undefined,
    loadMore: false,
    next: undefined,
    category: undefined,
    brand: undefined,
    filterLoading: false,
    total:undefined,
    filterOption:{}
  }),
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["allChargingStations", "allBrands", "allFilters"]),
  },
  methods: {
    ...mapActions(["getChargingStationFrontend", "getFilters"]),
    async selectCategory() {
      await this.getFilters({ category: this.category });
      this.filters = this.allFilters.results;
      this.brands = this.filters.brand.items;
    },
    async filter() {
      this.filterLoading = true;
      if (this.category != undefined) {
        this.filterOption.category = this.category;
      }
      if (this.brand != undefined) {
        this.filterOption.brand = this.brand;
      }
      await this.getChargingStationFrontend({
        ...this.filterOption,
        limit: this.limit,
        page: this.page,
      });

      this.chargingStations = this.allChargingStations.results;
      this.next =
        this.allChargingStations.pagination.next != undefined ? true : false;
      this.total = this.allChargingStations.pagination.total;
      this.filterLoading = false;
    },
    async nextAct() {
      this.loadMore = true;
      await this.getChargingStationFrontend({
        ...this.filterOption,
        limit: this.limit,
        page: this.allChargingStations.pagination.next,
      });
      this.allChargingStations.results.forEach((ele) => {
        this.chargingStations.push(ele);
      });
      this.loadMore = false;
      this.next =
        this.allChargingStations.pagination.next != undefined ? true : false;
    },
  },
  async created() {
    await this.getFilters();
    this.filters = this.allFilters.results;
    this.categories = this.filters.category.items;
    await this.getChargingStationFrontend({
      limit: this.limit,
      page: this.page,
    });
    this.chargingStations = this.allChargingStations.results;
    this.next =
      this.allChargingStations.pagination.next != undefined ? true : false;
    this.total = this.allChargingStations.pagination.total;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.divBox {
  box-shadow: 0 0 5px #bdb8b8;
  padding: 15px 20px 3px;
  border-radius: 10px;
  ul {
    padding-bottom: 0;
    padding-left: 0;
    list-style: none;
    li {
      margin-bottom: 0;
      font-size: 14px;
      .tag {
        margin-top: 10px;
        background: #0a77d3;
        display: inline-flex;
        padding: 3px 12px;
        color: #fff;
        border-radius: 30px;
      }
    }
  }
  .marker {
    cursor: pointer;
  }
  a {
    color: #000;
  }
}
.filterSection {
  .v-btn {
    margin-top: -5px;
    min-height: 50px;
    min-width: 200px;
  }
}
.navList {
  padding-left: 0;
  list-style: none;
  display: flex;
  font-size: 14px;
  li {
    .v-icon {
      font-size: 18px;
      margin-left: 5px;
    }
    // margin-right: 15px;
  }
}
</style>